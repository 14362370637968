<template>
  <div class="content-services">
    <Banner pageLocal="vehicleSchedule" />
    <section class="content-services-description">
			<div class="content-services-description-itens container">
				<div class="content-services-description-itens-header">
					<h1 class="title">{{ $t("vehicle_schedule.title_page")|stripHtml }}</h1>
				</div>
				<div class="content-services-description-itens-body" v-html="$t('vehicle_schedule.text_page')"></div>
			</div>
		</section>
		<section class="content-form-default">
			<form class="content-form-default-itens container">
				<input type="hidden" name="captation" value="form-site-vehicle-schedule">
				<input type="hidden" name="departamento" :value="$t('form.department_schedule')|stripHtml">
				<h2 class="title">{{ $t("vehicle_schedule.text_title_form")|stripHtml }}</h2>
				<div class="row">
					<div class="col-12 col-md-6 col-lg-4">
						<div class="content-input">
							<input type="text" name="name" :placeholder="$t('form.field_name')|stripHtml" required />
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-4">
						<div class="content-input">
							<input type="email" name="email" value="" :placeholder="$t('form.field_email')|stripHtml" required />
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-4">
						<div class="content-input">
							<input type="text" name="mobile" value="" :placeholder="$t('form.field_phone')|stripHtml" required />
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-4">
						<div class="content-input">
							<input type="text" name="document" value="" :placeholder="$t('form.field_document')|stripHtml" required />
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-4">
						<div class="content-input">
							<input type="text" name="product" value="" :placeholder="$t('form.field_product')|stripHtml" required />
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-4">
						<div class="content-input">
							<input type="text" name="year_vehicle" value="" :placeholder="$t('form.field_vehicle_year')|stripHtml" required />
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-4">
						<div class="content-input">
							<input type="text" name="type_service" value="" :placeholder="$t('form.field_type_service')|stripHtml" required />
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-4">
						<div class="content-input">
							<input type="text" name="vehicle_km" value="" :placeholder="$t('form.field_vehicle_km')|stripHtml" required />
						</div>
					</div>
					<!--  -->
					<template v-if="Object.keys(layout).length > 0">
						<div class="col-12 col-md-6 col-lg-4" v-if="layout.units_data.length > 1">
							<div class="content-input-select">
								<select name="unit_id" required>
								<option value="">{{$t('form.field_unit')|stripHtml}}</option>              
								</select>
								<svg
								xmlns="http://www.w3.org/2000/svg"
								width="11.351"
								height="7.638"
								viewBox="0 0 11.351 7.638"
								>
								<g transform="translate(0 0)">
									<path
									d="M11.169,101.336l-.372-.426a.57.57,0,0,0-.878,0l-4.241,4.832L1.432,100.9a.58.58,0,0,0-.439-.206.581.581,0,0,0-.439.206l-.372.424a.779.779,0,0,0,0,1l5.056,5.78a.6.6,0,0,0,.44.227h0a.6.6,0,0,0,.439-.227l5.051-5.764a.769.769,0,0,0,.181-.505A.761.761,0,0,0,11.169,101.336Z"
									transform="translate(0 -100.698)"
									/>
								</g>
								</svg>
							</div>
						</div>
						<input v-else type="hidden" name="unit" :value="layout.units_data[0].id">
					</template>
					<!--  -->
				</div>
				<h2 class="title mt-5">{{ $t("vehicle_schedule.title_schedule")|stripHtml }}</h2>
				<div class="row">
					<div class="col-12 col-lg-4">
						<div class="content-input">
							<input type="date" name="date_schedule" value="" :placeholder="$t('form.field_date_schedule')|stripHtml" required />
						</div>
					</div>
					<div class="col-12 col-lg-4">
						<div class="content-input-select">
							<select name="hour_schedule">
								<option value="">{{ $t("form.field_hour_schedule")|stripHtml }}</option>
								<option value="Manhã">Horário da Manhã</option>
								<option value="Tarde">Horário da Tarde</option>							
							</select>
							<svg xmlns="http://www.w3.org/2000/svg" width="11.351" height="7.638" viewBox="0 0 11.351 7.638"><g transform="translate(0 0)"><path d="M11.169,101.336l-.372-.426a.57.57,0,0,0-.878,0l-4.241,4.832L1.432,100.9a.58.58,0,0,0-.439-.206.581.581,0,0,0-.439.206l-.372.424a.779.779,0,0,0,0,1l5.056,5.78a.6.6,0,0,0,.44.227h0a.6.6,0,0,0,.439-.227l5.051-5.764a.769.769,0,0,0,.181-.505A.761.761,0,0,0,11.169,101.336Z" transform="translate(0 -100.698)"></path></g></svg>
						</div>
					</div>
					<div class="col-lg-12">
						<div class="content-input">
							<textarea :placeholder="$t('form.field_message_services')|stripHtml"></textarea>
						</div>
					</div>
					<div class="col-lg-12 mt-4">
						<div class="start-field">
							<input type="checkbox" name="receive_email" id="acept-email" value="" />
							<label for="acept-email">{{ $t('form.receive_email')|stripHtml }}</label>
						</div>
					</div>
					<div class="col-lg-12">
						<div class="start-field">
							<input type="checkbox" name="receive_sms" id="acept-sms" value="" />
							<label for="acept-sms">{{ $t('form.receive_sms')|stripHtml }}</label>
						</div>
					</div>
					<div class="col-lg-12">
						<div class="start-field">
							<input type="checkbox" name="accepted_terms" id="acept-polity" value="1" required />
							<label for="acept-polity">
								<router-link :to="{name: 'policyPrivacy'}"
								target="_blank">{{ $t('form.accepted_terms')|stripHtml }}</router-link>
							</label>
						</div>
					</div>
					<div class="col-12 mt-5">
						<button type="submit" class="btn-cotation-default">{{ $t('form.button_submit')|stripHtml }}</button>
					</div>
				</div>
			</form>
		</section>
  </div>
</template>

<script>
import Banner from "@/components/banners/BannerPages.vue";
import { mapGetters } from 'vuex';

export default {
	components: {
		Banner,
	},
	computed:{
		...mapGetters('Site', ['layout'])
	}
};
</script>

<style lang="scss">
.content-services {
	&-description {
		background: #3D5369;
		padding: 50px 0px;
		text-align: left;
		@media (max-width: 991px){
			text-align: center;
		}
		&-itens {
			&-header {
				margin-bottom: 30px;
				.title {
					position: relative;
					font-size: 40px;
					font-family: 'Font Black';
					color: var(--white);
					margin-bottom: 0;
					padding-bottom: 6px;
					@media (max-width: 991px){
						font-size: 20px;
						font-family: 'Font Medium';
					}
					&::before {
						content: '';
						position: absolute;
						bottom: 0;
						height: 4px;
						width: 128px;
						display: block;
						background: var(--main-color);
						box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
						border-radius: 10px;
						@media (max-width: 991px){
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}
			&-body {
				width: 60%;
				@media (max-width: 991px){
					width: 100%;
				}
				@media (min-width: 992px) and (max-width: 1199px){
					width: 50%;
				}
				p {
					font-size: 18px;
					font-family: 'Font Regular';
					color: var(--white);
					margin-bottom: 0;
					@media (max-width: 991px){
						font-size: 16px;
					}
				}
			}
		}
	}
}
.content-form-default {
	padding: 50px 0px;
	&-itens {
		.title {
			position: relative;
			font-size: 20px;
			font-family: 'Font Black';
			color: #262628;
			margin-bottom: 30px;
			padding-bottom: 6px;
			text-align: left;
			@media (max-width: 991px){
				font-size: 16px;
				font-family: 'Font Medium';
				text-align: center;
			}
			// &::before {
			// 	content: '';
			// 	position: absolute;
			// 	bottom: 0;
			// 	height: 4px;
			// 	width: 128px;
			// 	display: block;
			// 	background: var(--main-color);
			// 	box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
			// 	border-radius: 10px;
			// 	@media (max-width: 991px){
			// 		left: 50%;
			// 		transform: translateX(-50%);
			// 	}
			// }
		}
		.content-input {
      margin-bottom: 10px;
      input:not([type=date]) {
        height: 60px;
        width: 100%;
        border: 1px solid #cecece;
        border-radius: 5px;
        background: var(--white);
        padding-left: 12px;
        outline: none;
        font-size: 14px;
        font-family: "Font Regular";
        color: #5e5e5e;
        text-transform: uppercase;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #5e5e5e;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #5e5e5e;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #5e5e5e;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #5e5e5e;
        }
        &:not(:placeholder-shown){
          border: 0;
          box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
        }
      }
			input[type=date] {
        height: 60px;
        width: 100%;
        border: 1px solid #cecece;
        border-radius: 5px;
        background: var(--white);
        padding: 0px 12px;
        outline: none;
        font-size: 14px;
        font-family: "Font Regular";
        color: #5e5e5e;
        text-transform: uppercase;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #5e5e5e;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #5e5e5e;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #5e5e5e;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #5e5e5e;
        }
      }
      textarea {
        display: block;
        min-height: 160px;
        width: 100%;
        border: 1px solid #cecece;
        border-radius: 5px;
        background: var(--white);
        padding-left: 15px;
        padding-top: 15px;
        outline: none;
        font-size: 14px;
        font-family: "Font Regular";
        color: #5e5e5e;
        text-transform: uppercase;
        resize: none;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #5e5e5e;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #5e5e5e;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #5e5e5e;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #5e5e5e;
        }
        &:not(:placeholder-shown){
          border: 0;
          box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
        }
      }
      &-select {
        margin-bottom: 10px;
        select {
          height: 60px;
          width: 100%;
          border: 1px solid #cecece;
          border-radius: 5px;
          background: var(--white);
          padding: 0px 30px 0px 12px;
          outline: none;
          font-size: 14px;
          font-family: "Font Regular";
          color: #5e5e5e;
          text-transform: uppercase;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #5e5e5e;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: #5e5e5e;
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: #5e5e5e;
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: #5e5e5e;
          }
        }
        svg {
          position: absolute;
          right: 30px;
          top: 45%;
          transform: translateY(-50%);
          pointer-events: none;
        }
      }
    }
		.start-field {
			text-align: left;
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			label {
				font-size: 14px;
				font-family: 'Font Bold';
				color: #313134;
				margin-bottom: 0;
				a {
					color: var(--main-color) !important;
					text-decoration: underline !important;
					text-decoration-color: var(--main-color) !important;
					transition: 500ms;
					&:hover,&:focus {
						cursor: pointer;
						opacity: 0.6;
					}
				}
			}
			> input[type="checkbox"]:checked:before {
				content: "";
				height: 100%;
				width: 100%;
				background: url("../../assets/img/check.png");
				background-position: center !important;
				background-repeat: no-repeat !important;
			}
			> input[type="checkbox"]:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				background-color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 0px;
				border: 0;
			}
			> input[type="checkbox"] {
				border: 1px solid #CECECE;
				border-radius: 1;
				position: relative;
				height: 20px;
				width: 20px;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				outline: 0 !important;
				margin: 0;
				margin-right: 10px;
			}
		}
	}
}
</style>